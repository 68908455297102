const AUTO_SIGNUP_CONFIG = {
  eufy: {
    excludeCountry: ['au'],
    popActivateModal: true,
  },
  soundcore: {
    country: ['us', 'eu', 'eu-en', 'de', 'eu-de', 'uk', 'ca', 'fr', 'eu-fr'],
    popActivateModal: false,
  },
  anker: {
    country: ['us', 'eu-de', 'de', 'uk', 'eu-en', 'eu', 'ca'],
    popActivateModal: false,
  },
  nebula: {
    country: ['us', 'uk', 'eu', 'de', 'ca', 'eu-en', 'eu-de'],
    popActivateModal: false,
  },
};

module.exports = {
  AUTO_SIGNUP_CONFIG,
};
